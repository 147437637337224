import axios from "axios";

const fetchData = () => {
  const defaultOptions = {
    baseURL: "https://api.test.gatoga.ru",
    withCredentials: false,
    headers: {
      accept: "application/json",
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return instance;
};

export default fetchData();
