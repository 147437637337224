<template>
  <form
    class="auth-form mx-auto"
    v-if="!isCodeSend"
    @submit.prevent="callToUser"
  >
    <form-error-display v-if="error">{{ error }}</form-error-display>
    <div class="auth-form-text">Введите ваш номер телефона</div>
    <vue-tel-input
      class="auth-form-input"
      v-model="phone"
      ref="input"
    ></vue-tel-input>
    <button class="btn-blue btn-submit mt-4" type="submit">Далее</button>

    <div class="text-center">
      <a
        class="auth-form-link"
        href="https://vk.com/im?sel=-190214203"
        target="_blank"
        >Моей страны нет в списке</a
      >
    </div>
  </form>

  <form class="auth-form mx-auto" v-if="isCodeSend" @submit.prevent="login">
    <div class="auth-form-text auth-form-code text-center">
      Введите последние 4 цифры номера с&nbsp;которого вам поступил звонок.
      Отвечать на звонок не нужно.
    </div>
    <form-error-display v-if="error">{{ error }}</form-error-display>
    <input
      id="code"
      maxlength="4"
      class="form-control auth-form-input"
      placeholder="____"
      v-model="code"
      autofocus
    />
    <span class="timer text-center d-block mt-1" v-if="currentTime > 0">
      00:<span v-if="isOne">0</span>{{ currentTime }}</span
    >
    <button class="btn-blue btn-submit mt-2" @click.prevent="callToUser" v-else>
      Выслать код еще раз
    </button>
    <button class="btn-blue btn-submit mt-3" type="submit">Войти</button>
    <div class="text-center">
      <a
        class="auth-form-link"
        href="https://vk.com/im?sel=-190214203"
        target="_blank"
        >Мне не пришел код</a
      >
    </div>
  </form>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import FormErrorDisplay from "./FormErrorDisplay";

export default {
  name: "FormAuth",
  props: {
    modal: Object,
  },
  emits: ["currentStartEvent"],
  components: {
    FormErrorDisplay,
  },
  data() {
    return {
      isCodeSend: false,
      phone: "",
      code: "",
      countryCode: "",
      error: "",
      currentTime: 60,
      timer: null,
      isOne: false,
    };
  },
  watch: {
    currentTime(time) {
      if (time <= 9) {
        this.isOne = true;
      }
      if (time === 0) {
        this.stopTimer();
      }
    },
  },
  mounted() {
    let authModal = document.getElementById("auth");
    authModal.addEventListener("hidden.bs.modal", () => {
      this.isCodeSend = false;
      this.phone = "";
      this.code = "";
      this.stopTimer();
    });
  },
  methods: {
    ...mapMutations({ setToken: "user/SET_TOKEN", setAuth: "user/SET_AUTH" }),
    ...mapActions({ getProfile: "user/getProfile" }),
    getCountryCode() {
      this.countryCode = this.$refs.input.phoneObject.countryCallingCode;
    },
    async callToUser() {
      try {
        if (!this.isCodeSend) this.getCountryCode();
        // eslint-disable-next-line no-unused-vars
        const data = (
          await this.$api.auth.callToUser({
            phone_number: this.countryCode + this.phone,
          })
        ).data;
        this.isCodeSend = true;
        this.startTimer();
      } catch (error) {
        switch (error.response.status) {
          case 429:
            this.error =
              "Превышено допустимое количество запросов на сервер. Повторите попытку позже";
            break;
        }
      }
    },
    async login() {
      try {
        const data = (
          await this.$api.auth.getToken({
            phone_number: this.countryCode + this.phone,
            authentication_code: this.code,
          })
        ).data;
        localStorage.setItem("token", data.token);
        this.setToken(data.token);
        this.$emit("currentStartEvent");
        this.$router.push({
          name: "AccountMain",
        });
        this.modal.hide();
      } catch (error) {
        switch (error.response.status) {
          case 400:
            this.error = "Введен некорректный код.";
            break;
        }
      }
    },
    startTimer() {
      this.currentTime = 60;
      this.isOne = false;
      this.timer = setInterval(() => {
        this.currentTime--;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-form
  max-width: 360px
  text-align: left
  @media(max-width: $tablet)
    max-width: 312px
  &-text
    margin-bottom: 5px
    @media(max-width: $tablet)
      font-size: 14px
  &-input
    background: #1A316C
    border: 0
    border-radius: 9px
    color: #fff
    &:hover, &:active, &:focus
      background: #1A316C
      color: #fff !important
    &::placeholder
      color: #fff
      letter-spacing: 5px
  &-link
    display: inline-block
    margin-top: 20px
    text-decoration: underline
    @media(max-width: $tablet)
      font-size: 14px
    &:hover
      text-decoration: none
  &-code
    margin-bottom: 20px
  .timer
    color: $green
    font-size: 14px
</style>
